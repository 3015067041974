<!-- eslint-disable max-classes-per-file -->
<template>
  <v-container fluid>
    <TenderMaterialIntroText v-if="!readonly" :tender>
      <template #info_start>
        <p>Når du bruger de digitale dokumenter, genbruges informationen på tværs af materialet. Dokumenterne er indbyrdes forbundne, så fx informationer fra kravspecifikationen overføres til leveringskontrakten og opfordring til tilbud.</p>
        <p>SKI trepartsaftale er et obligatorisk bilag, når du bruger aftalen.</p>
        <p>CSR-bilaget er også obligatorisk, men det er valgfrit, om du vil anvende SKI’s bilag eller eget. Bruger du eget bilag, skal kravene være tilsvarende eller skærpede i forhold til kravene i SKI’s CSR-bilag. Du tilføjer eget bilag under ’Tilføj egne +’.</p>
        <p>Dokumenterne gemmes automatisk undervejs, og dine informationer og rettelser bliver automatisk overført til alle relevante dokumenter.</p>
      </template>
      <template #info_steps>
        <p class="mb-1">Sådan gør du:</p>
        <ol>
          <li>Angiv dine krav i <strong>kravspecifikationen</strong></li>
          <li>Færdiggør <strong>leveringskontrakt</strong> og <strong>opfordring til tilbud</strong> i én arbejdsgang</li>
          <li>Tilføj evt. eget CSR-bilag under <strong>Tilføj egne +</strong></li>
          <li>Marker dokumenternes status som <strong>'Færdig'</strong> for at aktivere knappen <strong>'Godkend og offentliggør materiale'</strong></li>
          <li>Kontroller og offentliggør dit materiale ved at klikke på <strong>'Godkend og offentliggør materiale'</strong></li>
        </ol>
      </template>
      <template #info_end>
        <p><strong>Bemærk:</strong> Når du vælger <strong>’Godkend og offentliggør materiale’</strong>, offentliggør du <strong>ikke</strong> for leverandørerne, før du i det efterfølgende trin har kontrolleret dit materiale og er klar.</p>
        <p>Brug de tre prikker til højre for <strong>’Angiv status’</strong>-knapperne, for at se og downloade dokumenterne.</p>
        <p>Afhold evt. en <strong>markedsdialog</strong>, inden du offentliggør dit indkøb: En markedsdialog kan give dig en bedre forståelse af markedet. Dialogen med leverandørerne kan hjælpe dig med at lave mere realistiske udbudskriterier og krav, som passer til det, markedet faktisk kan tilbyde. Du afholder markedsdialog under fanen ‘Kommunikation’.</p>
        <p>Har du spørgsmål eller brug for rådgivning, er du altid velkommen til at kontakte os.</p>
      </template>
    </TenderMaterialIntroText>
    <TenderMaterialTableV2
      :documentList
      :uploadModel
      :tender
      :readonly="false"
      :showFileInputMenu="true"
      @setDocumentDoneStatus="setDocumentDoneStatus"
      @removeFile="removeFile"
      @edit="onEdit($event)"
      @preview="onPreview($event)"
    >
      <template #editRequestForOffer="{ activatorType }">
        <RequestForOfferDialog
          :activatorType
          :tender
        />
      </template>
      <template #specificationInfo>
        <SimpleInfoDialog title="Kravspecifikation">
          <p>
            Den digitale kravspecifikation guider dig til at specificere dit behov på en overskuelig måde. Kravspecifikationen indeholder de gængse krav og behov på markedet og giver dig mulighed for at supplere med egne krav.
          </p>
          <p>
            Kravspecifikationen gemmes automatisk undervejs og dine valg, rettelser og informationer bliver automatisk overført til alle relevante dokumenter og bilag.
          </p>
          <p>
            I <b>’Preview’</b>-funktionen øverst i kravspecifikationen kan du løbende se og skifte mellem bilagene. Du kan også downloade bilagene enkeltvis eller samlet.
          </p>
          <p>
            Brug <b>’Vis som leverandør’</b>-funktionen i kravspecifikationen til løbende at se tilbudslisten, som den vil blive præsenteret for leverandøren. Du kan også agere leverandør og testudfylde tilbudslisten.
          </p>
          <p>
            Den udfyldte kravspecifikation bliver automatisk til leverandørens tilbudsliste og bruges efterfølgende i den automatiske evaluering.
          </p>
          <p>
            Leverandørerne kan først se tilbudslisten, når du offentliggør indkøbet.
          </p>
          <p>
            Klik på de tre prikker til højre for <b>'Angiv status'</b> for at se en forhåndsvisning og downloade kravspecifikationen.
          </p>
          <p>
            Hvis du har spørgsmål eller brug for rådgivning, er du velkommen til at kontakte os.
          </p>
        </SimpleInfoDialog>
      </template>
      <template #requestForOfferInfo>
        <SimpleInfoDialog title="Leveringkontrakt og Opfordring">
          <p>Det digitale spørgeskema guider dig til at udfylde din Leveringskontrakt og Opfordring til tilbud i én arbejdsgang. Når du har udfyldt dine informationer, genereres dokumenterne automatisk. Informationerne - eksempelvis dit valg af tildelingskriterie - anvendes efterfølgende i den digitalt understøttede evaluering.</p>
          <p>Du kan løbende se og skifte mellem Leveringskontrakt og Opfordring til tilbud i preview-funktionen.</p>
          <p>Når du  er færdig med leveringskontrakt og opfordringsskrivelse, ændrer du status fra 'kladde' til 'færdig' for at komme videre.</p>
          <p><b>CSR-bilaget</b> er obligatorisk, men du kan vælge, om du vil bruge SKI’s bilag eller dit eget. Hvis du vælger at bruge dit eget bilag, skal kravene være tilsvarende eller skærpede i forhold til kravene i SKI’s CSR-bilag. Du tilføjer eget bilag under <b>’Tilføj egne +’</b>.</p>
          <p><b>Bemærk:</b> Når du trykker på <b>’Godkend og offentliggør materiale’</b>, offentliggør du <b>ikke</b> for leverandørerne endnu. Det sker først i næste trin, når du har kontrolleret dit materiale og er klar.</p>
          <p>Brug de tre prikker til højre for <b>’Angiv status’</b>-knapperne, for at se og downloade dokumenterne.</p>
          <p>Hvis du har spørgsmål eller brug for rådgivning, er du velkommen til at kontakte os.</p>
        </SimpleInfoDialog>
      </template>

    </TenderMaterialTableV2>

    <v-row v-if="!readonly" no-gutters>
      <v-col style="max-width: 350px !important" class="text-center">
        <img
          alt="Pil ned"
          class="ml-1"
          height="40"
          src="@/assets/arrow_green_down.svg"
        />
        <div class="ml-4">
          <PublishTenderDialog
            :tender
            :disabled="!mixin.publishTenderEnabled()"
            @updateTab="updateTab"
          />
          <TenderMaterialAfterText :publishTenderEnabled="mixin.publishTenderEnabled()" :isTemplate />
        </div>
      </v-col>
    </v-row>
    <SpecificationEditDialog
      v-if="!readonly"
      v-model="showSpecificationEditor"
      :tender
      @questionnaireUpdated="updateTender($event)"
    >
      <p>Her udarbejder du en kravspecifikation for funktionelle krav og non-funktionelle krav til dit indkøb. Den skal indeholde alle de krav, du har til løsningen.</p>
      <p>Kravspecifikationen indgår sammen med opfordringsskrivelsen og leveringskontrakten i det materiale, du skal offentliggøre til potentielle tilbudsgivere for, at de kan afgive tilbud på dit indkøb.</p>
      <p>Du bliver i det følgende præsenteret en række spørgsmål og krav, hvor du får mulighed for at præcisere kontraktmaterialet til dit indkøb.</p>
      <p>I kravspecifikationen vil du blive mødt med forskellige typer af krav:</p>
      <ul class="mb-4">
        <li>Krav, det ikke er muligt at ændre.</li>
        <li>Krav, der kan fra- eller tilvælges samt tilpasses.</li>
        <li>Krav, der beskriver din kvalitetsevaluering.</li>
        <li>Er der krav, du ønsker at få med, som ikke fremgår af kravspecifikationen, har du mulighed for at tilføje dem.</li>
      </ul>
      <p>Det er vigtigt, at du gennemgår alle spørgsmål og kravsrækker, så du får et fyldestgørende kontraktmateriale med alle de krav, der er nødvendige og relevante for dit indkøb.</p>
    </SpecificationEditDialog>
    <SpecificationPreviewDialogWithoutActivator
      v-model="showSpecificationViewer"
      :tender
    />
    <LoadingSpinner :visible="isLoading" />
  </v-container>
</template>

<script setup lang="ts">

import { ref, onMounted, watch, computed } from 'vue';
import {
  LoadingSpinner,
  MaterialDocument,
  MaterialFileInfo,
  useStore,
  TenderMaterialTableV2,
  SimpleInfoDialog,
  PublishTenderDialog,
  RequestForOfferDialog,
  SpecificationPreviewDialogWithoutActivator,
  TenderMaterialIntroText,
  TenderMaterialAfterText,
  SpecificationEditDialog,
  DeepQuestionnaire,
  TenderStateEnum,
  TenderMaterialMixin,
  Tender,
} from '@dims/components';
import { TenderData0219 } from '@/models/Tender';

/** Presentations of documents when tender is in draft state.
 * Each of the standard files can be replaced by a custom uploaded file
 * Additional custom files can be uploaded
 * For the standard documents, link to questionnaire dialog
 * For custom documents, download.
 */
const emit = defineEmits<{
  isLoaded: [true],
  updateTab: [string],
}>();
const { readonly = false, tender } = defineProps<{
  readonly?: boolean,
  tender: Tender,
}>();
const store = useStore();
const showSpecificationEditor = ref(false);
const showSpecificationViewer = ref(false);
const isLoading = ref(false);

const mixin = ref<TenderMaterialMixin>(new TenderMaterialMixin(tender, false, false, true, true));

onMounted(() => {
  emit('isLoaded', true);
});

watch(
  () => tender,
  (newTender: Tender) => {
    mixin.value.tender = newTender;
  },
);

const documentList = computed(() => mixin.value.getDocumentLines());

async function removeFile(file: MaterialFileInfo) {
  isLoading.value = true;
  try {
    await mixin.value.removeFile(file);
  } catch (e) {
    console.error(e);
    store.setSnackbarText('Kunne ikke slette filen.');
  } finally {
    isLoading.value = false;
  }
}

function onEdit(doc: MaterialDocument) {
  if (doc.kind === 'specification') {
    showSpecificationEditor.value = true;
  }
}

function onPreview(doc: MaterialDocument) {
  if (doc.kind === 'specification') {
    showSpecificationViewer.value = true;
  }
}

function updateTab(tabName: string) {
  emit('updateTab', tabName);
}

async function setDocumentDoneStatus(doc: MaterialDocument, completed: boolean) {
  isLoading.value = true;
  try {
    await mixin.value.setDocumentDoneStatus(doc, completed);
  } catch (e) {
    console.error('Could not update tender', e);
    store.setSnackbarText('Kan ikke markere dokument som færdigt.');
  } finally {
    isLoading.value = false;
  }
}

const isTemplate = computed(() => tender.state === TenderStateEnum.Template);

const uploadModel = computed(() => mixin.value.getUploadModel());

/* async */ function updateTender(questionnaire: DeepQuestionnaire) {
  const data = tender.data as TenderData0219;
  const p = questionnaire.questions[0];
  // TODO: syncronize data to match questionnaire
  // data.systemType = SystemTypesEnum.PartII;
  // const update: UpdateTenderRequest = {
  //   id: tender.id,
  //   data,
  // };
  // await this.store.updateTenderAction(update);
  console.log(`Questionnaire updated ${tender.id} X ${data.systemType} X ${p?.questionId}`);
}

</script>
