<template>
  <div>
    <v-dialog
      v-if="!readonly"
      v-model="dialog"
    >
      <template #activator="{ props }">
        <ArrowButton v-bind="props" class="my-5">Vælg integrationer</ArrowButton>
      </template>
      <v-card :title="question.title">
        <template #append>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
        <v-card-text>
          {{ question.helpText }}
        </v-card-text>
        <v-divider class="my-0"></v-divider>
        <v-card-title>Obligatoriske - inkluderet i prisen</v-card-title>
        <v-card-text>
          <v-data-table
            v-model="mandatoryIntegrationList"
            :loading="isLoading"
            loading-text="Henter data..."
            :headers
            :search
            :items="mandatoryIntegrationList"
            item-value="integrationKombitId_0219"
            :sort-by="[{ key: 'integrationKombitId_0219' }]"
            class="bg-canvas"
            dense
            :items-per-page-options="[10, 20, 50, -1]"
            no-data-text="Ingen integrationer"
            no-results-text="Din søgning fandt ingen integrationer"
          >
            <template
              #item.integrationKombitId_0219="{ item }"
            >
              <td>&nbsp;</td>
              <td>{{ item.integrationKombitId_0219 }}</td>
            </template>
            <template
              #item.integrationKombitText_0219="{ item }"
            >
              <td>{{ item.integrationKombitText_0219 }}</td>
            </template>
            <template
              #item.useCaseIdList="{
                item,
              }"
            >
              <td>
                <v-chip
                  class="useCaseChip"
                  size="x-small"
                  v-for="useCase in item.useCaseIdList"
                  :key="useCase"
                >
                  {{ useCase }}</v-chip>
              </td>
            </template>
          </v-data-table>
        </v-card-text>
        <v-divider class="my-0"></v-divider>
        <v-card-title>Supplerende - kan tilvælges efter behov</v-card-title>
        <v-card-text>
          <v-data-table
            v-model="supplementalSelectedIntegrationList"
            :loading="isLoading"
            loading-text="Henter data..."
            :headers="headersWithCheckbox"
            :search
            :items="supplementalIntegrationList"
            item-value="integrationKombitId_0219"
            :sort-by="[{ key: 'integrationKombitId_0219' }]"
            class="bg-canvas"
            show-select
            dense
            :items-per-page-options="[10, 20, 50, -1]"
            no-data-text="Ingen integrationer"
            no-results-text="Din søgning fandt ingen integrationer"
          >
            <template #top>
              <v-toolbar flat color="canvas">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Søg"
                  single-line
                  variant="outlined"
                  clearable
                  density="compact"
                  hide-details
                ></v-text-field>
              </v-toolbar>
            </template>
            <template
              #item.integrationKombitId_0219="{ item }"
            >
              <td>{{ item.integrationKombitId_0219 }}</td>
            </template>
            <template
              #item.integrationKombitText_0219="{ item }"
            >
              <td>{{ item.integrationKombitText_0219 }}</td>
            </template>
            <template
              #item.useCaseIdList="{
                item,
              }"
            >
              <td>
                <v-chip
                  class="useCaseChip"
                  size="x-small"
                  v-for="useCase in item.useCaseIdList"
                  :key="useCase"
                >
                  {{ useCase }}</v-chip>
              </td>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="secondary-button" @click="dialog = false">
            Annuller
          </v-btn>
          <v-btn
            class="primary-button"
            @click="save()"
          >
            Gem
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-table dense class="mt-5 bg-canvas">
      <template #default>
        <caption class="table-header text-primary text-left pt-3 pl-4">Obligatoriske integrationer</caption>
        <thead>
          <tr>
            <th class="text-left" width="80px">Id</th>
            <th class="text-left">Navn</th>
          </tr>
        </thead>
        <tbody v-if="mandatoryIntegrationList?.length">
          <tr v-for="s in mandatoryIntegrationList" :key="s?.integrationKombitId_0219">
            <td>{{ s?.integrationKombitId_0219 }}</td>
            <td>{{ s?.integrationKombitText_0219 }}</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="2" class="text-center">Ingen obligatoriske integrationer</td>
          </tr>
        </tbody>
      </template>
    </v-table>
    <v-table dense class="mt-5 bg-canvas">
      <template #default>
        <caption class="table-header text-primary text-left pt-3 pl-4">Tilvalgte supplerende integrationer</caption>
        <thead>
          <tr>
            <th class="text-left" width="80px">Id</th>
            <th class="text-left">Navn</th>
          </tr>
        </thead>
        <tbody v-if="savedSelectedSupplementalIntegrations?.length">
          <tr v-for="s in savedSelectedSupplementalIntegrations" :key="s.value?.integrationKombitId_0219">
            <td>{{ s.value?.integrationKombitId_0219 }}</td>
            <td>{{ s.value?.integrationKombitText_0219 }}</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="2" class="text-center">Ingen supplerende integrationer tilvalgt</td>
          </tr>
        </tbody>
      </template>
    </v-table>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, onMounted } from 'vue';
import { Tender, useBackend, PlaceholderQuestion, GlobalListValue } from '@dims/components';
import { KombitIntegrationPlaceholderValue } from '@/models/PIM/PlaceholderValue';
import { IntegrationKombit } from '@/models/PIM/GlobalLists/IntegrationKombit';
import { IntegrationKombitWithUseCaseIdList } from '@/models/PIM/GlobalLists/IntegrationKombitWithUseCaseIdList';

const emit = defineEmits<{ addPlaceholderValue: [PlaceholderQuestion] }>();
const { tender, question, readonly = false } = defineProps<{
  tender: Tender,
  question: PlaceholderQuestion,
  readonly?: boolean }>();
const backend = useBackend();
const integrationMetadataList = ref<GlobalListValue<IntegrationKombit>[]>([]);
const mandatoryIntegrationList = ref<IntegrationKombitWithUseCaseIdList[]>([]);
const supplementalIntegrationList = ref<IntegrationKombitWithUseCaseIdList[]>([]);
const supplementalSelectedIntegrationList = ref<string[]>([]);
const search = ref('');
const dialog = ref(false);
const isLoading = ref(false);
const headersWithCheckbox = [
  { title: 'Id', value: 'integrationKombitId_0219', width: '80px' },
  { title: 'Navn', value: 'integrationKombitText_0219', width: '50%' },
  { title: 'Use cases', value: 'useCaseIdList' },
];
const headers = [
  { title: '', value: '', width: '60px' },
  { title: 'Id', value: 'integrationKombitId_0219', width: '80px' },
  { title: 'Navn', value: 'integrationKombitText_0219', width: '50%' },
  { title: 'Use cases', value: 'useCaseIdList' },
];

const savedSelectedSupplementalIntegrations = computed(() => {
  const placeholderValue = question.placeholderValue as KombitIntegrationPlaceholderValue | undefined;
  if (!placeholderValue) {
    return [];
  }
  const globalListValues = placeholderValue.values?.sort((a, b) => ((a.value?.integrationKombitId_0219 ?? '') < (b.value?.integrationKombitId_0219 ?? '') ? -1 : 1));
  return globalListValues;
});

watch(
  savedSelectedSupplementalIntegrations,
  async () => {
    // Refresh data when contents of placeholder value is changed. This happens e.g. when customer changes selected use cases or selected area
    await fetchData();
  },
);

watch(
  dialog,
  async () => {
    // Refresh data when dialog is shown
    if (dialog.value) {
      await fetchData();
    }
  },
);

onMounted(async () => {
  await fetchData();
});

async function fetchData() {
  isLoading.value = true;
  integrationMetadataList.value = await fetchIntegrationListFromMetadata();
  await fetchMandatoryIntegrationList();
  await fetchSupplementalIntegrationList();
  isLoading.value = false;
}

async function fetchIntegrationListFromMetadata() {
  return backend.pimDataService.fetchGlobalListValues<IntegrationKombit>('IntegrationKombit_0219');
}

async function fetchMandatoryIntegrationList() {
  // Clear content of global list
  mandatoryIntegrationList.value.splice(0);

  const mandatoryIntegrationListForTender = await backend.tenderService.getKombitMandatoryIntegrations(tender.id);

  // Convert from keyvaluepair array to IntegrationKombitWithUseCaseIdList entries
  for (const keyValue of mandatoryIntegrationListForTender) {
    const integrationKombit: IntegrationKombitWithUseCaseIdList = {
      integrationKombitId_0219: keyValue.key,
      integrationKombitText_0219: integrationMetadataList.value.first((i) => i.value?.integrationKombitId_0219 === keyValue.key).value?.integrationKombitText_0219 ?? '',
      useCaseIdList: keyValue.value,
    };
    mandatoryIntegrationList.value.push(integrationKombit);
  }
}

async function fetchSupplementalIntegrationList() {
  // Clear contents of global lists
  supplementalIntegrationList.value.splice(0);
  supplementalSelectedIntegrationList.value.splice(0);

  const supplementalIntegrationListForTender = await backend.tenderService.getKombitSupplementalIntegrations(tender.id);

  // Convert from dictionary with text values to IntegrationKombitWithUseCaseIdList entries
  for (const keyValue of supplementalIntegrationListForTender) {
    const integrationWithUseCaseList: IntegrationKombitWithUseCaseIdList = {
      integrationKombitId_0219: keyValue.key,
      integrationKombitText_0219: integrationMetadataList.value.first((i) => i.value?.integrationKombitId_0219 === keyValue.key).value?.integrationKombitText_0219 ?? '',
      useCaseIdList: keyValue.value,
    };
    supplementalIntegrationList.value.push(integrationWithUseCaseList);
  }

  // Also add to list of selected integrations if integration is selected (from placeholder value)
  supplementalSelectedIntegrationList.value = supplementalIntegrationList.value
    .filter((i) => savedSelectedSupplementalIntegrations.value?.some((c) => c.value?.integrationKombitId_0219 === i.integrationKombitId_0219))
    .map((x) => x.integrationKombitId_0219);
}

const mapSelectedIntegrationListToPlaceHolderValue = computed((): GlobalListValue<IntegrationKombit>[] => {
  // Convert list of selected IntegrationKombitWithUseCaseIdList to list of GlobalListValue<IntegrationKombit> by selecting items from integrationMetadataList with matching id's in supplementalSelectedIntegrationList
  const placeholderValue = integrationMetadataList.value.filter((i) => supplementalSelectedIntegrationList.value.some((s) => s === i.value?.integrationKombitId_0219));
  return placeholderValue;
});

function save() {
  const placeholderValue: KombitIntegrationPlaceholderValue = { type: 'KombitIntegration', values: mapSelectedIntegrationListToPlaceHolderValue.value };
  question.placeholderValue = placeholderValue;
  emit('addPlaceholderValue', question);
  dialog.value = false;
}

</script>
<style scoped>
.table-header {
  font-family: Oswald, sans-serif;
}
</style>
