<!--
  DO NOT EDIT THIS FILE

  File is duplicated in site and across sites, so should be kept in sync

  -------

  Sorry, but I have changed this file regardless of the above comment :-)
  So this file has been edited and is no longer in sync with other sites.
  Here is what was changed:

  PBI 22138:
    For 02.19 DIS (and only 02.19 DIS) the question "Ja, alle krav er opfyldt / Nej, alle krav er ikke opfyldt" has been removed as requested

-->
<template>
  <OfferSandboxBase
    @reloadOfferSample="createSandboxOfferQuestionnaire()"
    backgroundColor="white"
    :visible="isDIS"
  >
    <v-container fluid>
      <v-row>
        <v-col>
          <v-card class="pa-3 bg-canvas">
            <v-card-text>
              <div class="d-flex">
                <div>
                  <div class="d-flex align-center">
                    <h1 class="mt-0 mb-2 font-weight-bold">
                      Udfyld dit tilbud
                    </h1>
                    <v-chip
                      color="darkPurple"
                      class="ml-2"
                    >Leverandørvisning</v-chip>
                  </div>

                  <div class="mt-2 mb-7">
                    <p>
                      Kravbesvarelse og prisafgivelse herunder udgør dit tilbud
                    </p>
                    <p>
                      Udfyld dit tilbud ved (1) at besvare evalueringskravene,
                      (2) afgive priser og (3) bekræfte, at dit tilbud opfylder
                      samtlige af kundens krav.
                    </p>
                  </div>
                </div>
              </div>
              <v-card>
                <v-card-text class="pa-10">
                  <v-row>
                    <v-col cols="auto">
                      <StepStatus
                        :label="1"
                        :complete="isSectionComplete(specificationSections)"
                      />
                    </v-col>
                    <v-col>
                      <h2 class="mt-0 mb-2">Kravbesvarelse</h2>
                      <p>
                        Besvarelsen omhandler krav til servicen samt øvrige krav
                        som kunden måtte have tilvalgt.
                      </p>
                      <p>
                        Gennemlæs alt materialet på overbliksfanen inden du udfylder din besvarelse og
                        uploader bilagene.
                      </p>
                      <v-dialog v-model="offerPreviewDialog" max-width="95%">
                        <template #activator="{ props }">
                          <ArrowButton
                            v-bind="props"
                            class="mt-2"
                            :class="{ secondary: isSectionComplete(specificationSections) }"
                          >Besvar evalueringskrav</ArrowButton>
                        </template>
                        <v-card>
                          <OfferDetailsForm
                            v-if="offerQuestionnaire"
                            :tender
                            :offerQuestionnaire
                            :tabType="'SupplierSpecification'"
                            :readonly="true"
                            @closeDialog="closeOfferPreviewDialog"
                          />
                        </v-card>
                      </v-dialog>
                    </v-col>
                  </v-row>
                  <v-divider class="my-8"></v-divider>
                  <v-row>
                    <v-col cols="auto">
                      <StepStatus
                        :label="2"
                        :complete="isSectionComplete([priceSection])"
                      />
                    </v-col>
                    <v-col>
                      <h2 class="mt-0 mb-2">Prisafgivelse</h2>
                      <p>
                        Prisafgivelsen er opdelt i en række priselementer, der
                        afspejler kundens behov.
                      </p>
                      <p>
                        Indledningsvist skal du vælge den/de beregningsenheder, du
                        ønsker er gældende for dit tilbud og dermed beregningen
                        af den evalueringstekniske pris.
                        <br />
                        Dernæst skal du angive en prisreguleringsprocent, som du
                        kan vælge at gøre brug af i kontraktens varighed, jf.
                        bilag 4.1.
                      </p>
                      <p>
                        Efterfølgende skal du afgive priser for hvert
                        priselement. Hvert priselement har én eller flere
                        pristabeller med prispunkter, du skal udfylde. I takt
                        med din udfyldelse, kan du se prisen aggregeret op pr.
                        pristabel og priselement.
                      </p>
                      <p>
                        I 'Opsummering' (til højre), kan du følge de priser, du
                        har givet, samt den evalueringstekniske pris.
                      </p>
                      <p>
                        <b>Bemærk</b> priser skal afgives ekskl. moms og inkl.
                        kundebetaling til SKI.
                        <DimsTooltip>
                          <p>
                            Leverandøren skal på vegne af Kunden videreformidle
                            kundebetalingen på 1,5 % af Kundens købssum til SKI.
                            Leverandøren skal derfor indregne kundens betaling
                            til SKI på 1,5 % i sine tilbudspriser i det konkrete
                            indkøb.
                          </p>
                          <p>Kundebetalingen beregnes efter følgende formel:</p>
                          <p class="text-decoration-underline">
                            Kundens købssum eksklusive moms i DKK x 1,5 % =
                            Kundebetalingen i DKK
                          </p>
                          <p>
                            Leverandørens rapportering samt formidling af
                            kundens betaling til SKI er beskrevet nærmere i
                            Bilag A Trepartsaftale.
                          </p>
                        </DimsTooltip>
                      </p>
                      <v-dialog v-model="offerFormDialog" max-width="95%">
                        <template #activator="{ props }">
                          <ArrowButton
                            v-bind="props"
                            class="mt-2"
                            :class="{ secondary: isSectionComplete([priceSection]) }"
                          >Afgiv priser
                          </ArrowButton>
                        </template>
                        <v-card>
                          <OfferDetailsForm
                            v-if="offerQuestionnaire"
                            :tender
                            :offerQuestionnaire
                            :tabType="'SupplierOffer'"
                            :readonly="true"
                            @closeDialog="closeOfferFormDialog"
                          />
                        </v-card>
                      </v-dialog>
                    </v-col>
                  </v-row>
                  <v-divider class="my-8"></v-divider>
                  <v-row>
                    <v-col cols="auto">
                      <StepStatus :label="3" :complete="false" />
                    </v-col>
                    <v-col>
                      <p>
                        Du kan her uploade det ESPD, som ligger til grund for
                        din optagelse på aftalen.
                      </p>
                      <v-btn class="primary-button-with-arrow">
                        <span>Upload ESPD-dokument</span>
                        <v-icon size="small">mdi-upload</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-divider class="my-8"></v-divider>
                  <v-row>
                    <v-col cols="auto">
                      <StepStatus
                        :label="4"
                        :complete="false"
                      />
                    </v-col>
                    <v-col>
                      <p>Du kan her udfylde et tilbudsbrev og vedhæfte dette til dit tilbud, såfremt det er relevant. Dette er valgfrit.</p>
                      <v-btn
                        class="primary-button-with-arrow"
                      >
                        <span>Upload tilbudsbrev</span>
                        <v-icon size="small">mdi-upload</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-divider class="my-8"></v-divider>
                  <v-row>
                    <v-col cols="auto">
                      <StepStatus :label="5" :complete="false" />
                    </v-col>
                    <v-col>
                      <h2 class="mt-0 mb-2">Se og send tilbud</h2>
                      <p>
                        Færdiggør trin 1-4 for at sende dit tilbud
                      </p>
                      <v-dialog persistent scrollable max-width="80%">
                        <template #activator="{ props }">
                          <ArrowButton
                            disabled
                            v-bind="props"
                            class="mt-2"
                          >Se og send tilbud</ArrowButton>
                        </template>
                        <v-card> </v-card>
                      </v-dialog>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="4">
          <TcoCalculationSummary
            :questions="tcoCalculationQuestions"
            :tcoCalculationResult
            cardStyle="#fff9ef"
            tableStyle="transparent"
          />
        </v-col>
      </v-row>
    </v-container>
  </OfferSandboxBase>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { Tender, OfferSandboxBase, OfferDetailsForm, useBackend, usePreviewOfferQuestionnaire, DeepQuestionnaireRoot } from '@dims/components';
import TcoCalculationSummary from './OfferDetails/TcoCalculation/TcoCalculationSummary.vue';
import {
  setSupplierPriceTab,
  setSupplierSpecificationTabs,
} from '@/services/questionnaireTabConfig';

const { tender } = defineProps<{ tender: Tender }>();
const backend = useBackend();
const offerFormDialog = ref(false);
const offerPreviewDialog = ref(false);
const offerQuestionnaire = ref<DeepQuestionnaireRoot>();
const priceSection = ref(0);
const specificationSections = ref<number[]>([]);

async function createSandboxOfferQuestionnaire() {
  offerQuestionnaire.value = await usePreviewOfferQuestionnaire(tender.id, backend);
  priceSection.value = setSupplierPriceTab(
    offerQuestionnaire.value.questionnaire,
  );
  specificationSections.value = setSupplierSpecificationTabs(
    offerQuestionnaire.value.questionnaire,
  );
  specificationSections.value = specificationSections.value.filter(
    (section) => section !== -1,
  );
}

// Questions used for offer status overview
const tcoCalculationQuestions = computed(() => offerQuestionnaire.value?.questionnaire.questions[2]?.questions?.filter((x) => x.enabled && !x.key?.includes('tcoExcludedQuestion')) ?? []);

const tcoCalculationResult = computed(() => offerQuestionnaire.value?.questionnaire.tcoCalculationResult);

function isSectionComplete(sections: number[]) {
  const qq = offerQuestionnaire.value?.questionnaire.questions;
  return qq
    ? sections.every((section) => qq[section]?.questions?.every((q) => q.complete))
    : false;
}

function closeOfferFormDialog() {
  offerFormDialog.value = false;
}

function closeOfferPreviewDialog() {
  offerPreviewDialog.value = false;
}

const isDIS = computed(() => tender.awardForm === 'DIS');

</script>
